<template>
  <svg width="316" height="229" viewBox="0 0 316 229" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.1" d="M177.449 44.9076C158.31 44.2227 140.08 37.8644 122.675 30.9065C105.269 23.9486 88.0295 16.2388 69.3497 12.5529C57.3351 10.1818 43.5935 9.84703 33.915 16.4762C24.5973 22.868 21.587 33.8711 19.9694 44.0919C18.7511 51.7803 18.0355 59.8705 21.374 67.0689C23.6894 72.0667 27.8027 76.267 30.6445 81.07C40.5418 97.7252 33.5483 118.264 22.823 134.527C17.796 142.157 11.9557 149.441 8.07606 157.555C4.19637 165.67 2.39847 174.981 5.7932 183.266C9.16131 191.484 17.1839 197.644 25.8718 201.982C43.5226 210.793 64.3138 213.313 84.6023 214.741C129.5 217.903 174.634 216.534 219.649 215.164C236.309 214.656 253.04 214.141 269.429 211.49C278.53 210.017 287.925 207.682 294.534 202.039C302.92 194.878 304.999 182.748 299.381 173.769C289.951 158.706 263.896 154.965 257.296 138.797C253.67 129.9 257.396 119.99 262.666 111.735C273.974 94.0331 292.926 78.5011 293.925 58.2664C294.611 44.3658 285.497 30.4499 271.41 23.8725C256.645 16.9541 236.164 17.8307 225.276 29.2416C214.057 40.9995 194.345 45.5102 177.449 44.9076Z" fill="#7E6A97"/>
<path d="M154.748 165.081L50.1629 164.573L39.771 140.304L76.5171 137.648L70.1127 111.038L142.792 101.658L73.1957 77.5682L79.3435 76.1139L191.253 94.2926L185.09 135.819L176.281 158.641L154.748 165.081Z" fill="#7E6A97"/>
<path d="M51.7671 154.408C51.7671 154.408 58.1838 153.386 62.0338 157.901C62.0338 157.901 59.2104 160.116 53.136 157.049L51.7671 154.408Z" fill="#7E6A97"/>
<path d="M51.0949 154.308C51.0949 154.308 47.0004 159.325 49.0171 164.911C49.0171 164.911 52.3538 163.581 52.7174 156.809L51.0949 154.308Z" fill="#7E6A97"/>
<path d="M47.416 146.06C47.416 146.06 49.9827 141.372 60.2493 147.934C60.2493 147.934 58.8805 151.086 54.346 150.831C49.8116 150.575 48.6138 148.53 47.416 146.06Z" fill="#7E6A97"/>
<path d="M46.7531 145.722C46.7531 145.722 41.4273 145.114 40.9109 157.253C40.9109 157.253 44.2873 157.95 46.6859 154.11C49.0845 150.271 48.1006 148.117 46.7531 145.722Z" fill="#7E6A97"/>
<path d="M214.418 145.744C214.418 145.744 207.491 154.162 212.991 166.889L157.685 190.772L152.378 191.879L149.933 181.231L151.568 167.792V160.448L214.418 145.744Z" fill="#D5D6DB"/>
<path d="M39.771 140.304L116.319 126.409L217.748 142.497L154.749 165.081L39.771 140.304Z" fill="#7E6A97"/>
<path d="M39.771 140.304L116.319 126.409L217.748 142.497L154.749 165.081L39.771 140.304Z" fill="#7E6A97"/>
<path d="M39.7711 140.301C39.7711 140.301 32.6119 153.806 40.8619 166.28L154.376 190.772C154.376 190.772 147.33 180.014 154.739 165.081L39.7711 140.301Z" fill="#7E6A97"/>
<path opacity="0.1" d="M39.7711 140.301C39.7711 140.301 32.6119 153.806 40.8619 166.28L154.376 190.772C154.376 190.772 147.33 180.014 154.739 165.081L39.7711 140.301Z" fill="black"/>
<path opacity="0.1" d="M43.1536 144.983C43.1536 144.983 39.0622 154.865 43.1536 161.26V144.983Z" fill="black"/>
<path d="M154.376 190.772L217.748 163.846C217.748 163.846 221.292 164.607 219.001 167.193C216.709 169.779 155.598 193.358 155.598 193.358L40.8618 166.28L154.376 190.772Z" fill="#7E6A97"/>
<path opacity="0.1" d="M154.376 190.772L217.748 163.846C217.748 163.846 221.292 164.607 219.001 167.193C216.709 169.779 155.598 193.358 155.598 193.358L40.8618 166.28L154.376 190.772Z" fill="black"/>
<path opacity="0.1" d="M149.181 167.801C149.181 167.801 143.07 175.103 149.181 186.056V167.801Z" fill="black"/>
<path opacity="0.1" d="M178.768 167.678L151.356 176.784L151.453 177.072L178.864 167.966L178.768 167.678Z" fill="black"/>
<path opacity="0.1" d="M184.258 166.772L151.348 179.233L151.456 179.517L184.366 167.056L184.258 166.772Z" fill="black"/>
<path opacity="0.1" d="M189.3 166.728L151.531 182.121L151.647 182.403L189.416 167.01L189.3 166.728Z" fill="black"/>
<path opacity="0.1" d="M194.783 165.41L151.972 184.666L152.098 184.943L194.909 165.687L194.783 165.41Z" fill="black"/>
<path opacity="0.1" d="M199.389 165.416L152.311 185.906L152.433 186.185L199.512 165.695L199.389 165.416Z" fill="black"/>
<path opacity="0.1" d="M209.465 163.712L153.021 187.987L153.142 188.266L209.586 163.992L209.465 163.712Z" fill="black"/>
<path opacity="0.3" d="M68.2092 141.788L121.987 130.531L154.749 136.768L110.681 150.916L68.2092 141.788Z" fill="white"/>
<path d="M217.748 142.497C217.748 142.497 220.529 143.614 218.897 145.339C217.265 147.064 154.74 165.081 154.74 165.081L217.748 142.497Z" fill="#7E6A97"/>
<path d="M244.756 116.481C244.756 116.481 237.832 124.897 243.332 137.626L188.027 161.51L182.719 162.623L180.275 151.974L181.934 138.539V131.185L244.756 116.481Z" fill="#D5D6DB"/>
<path d="M70.1128 111.038L146.658 97.1465L248.09 113.235L185.09 135.819L70.1128 111.038Z" fill="#3A3768"/>
<path d="M70.1006 111.038C70.1006 111.038 62.9384 124.544 71.1884 137.018L184.702 161.51C184.702 161.51 177.659 150.742 185.078 135.816L70.1006 111.038Z" fill="#3A3768"/>
<path opacity="0.1" d="M70.1006 111.038C70.1006 111.038 62.9384 124.544 71.1884 137.018L184.702 161.51C184.702 161.51 177.659 150.742 185.078 135.816L149.181 128.079L70.1006 111.038Z" fill="black"/>
<path opacity="0.1" d="M73.4921 115.721C73.4921 115.721 69.4038 125.599 73.4921 131.998V115.721Z" fill="black"/>
<path d="M184.715 161.51L248.09 134.584C248.09 134.584 251.631 135.344 249.34 137.931C247.048 140.517 185.937 164.096 185.937 164.096L71.2007 137.018L184.715 161.51Z" fill="#3A3768"/>
<path opacity="0.1" d="M184.715 161.51L248.09 134.584C248.09 134.584 251.631 135.344 249.34 137.931C247.048 140.517 185.937 164.096 185.937 164.096L71.2007 137.018L184.715 161.51Z" fill="black"/>
<path opacity="0.1" d="M179.52 138.539C179.52 138.539 173.409 145.841 179.52 156.794V138.539Z" fill="black"/>
<path opacity="0.1" d="M209.109 138.419L181.698 147.525L181.794 147.813L209.205 138.707L209.109 138.419Z" fill="black"/>
<path opacity="0.1" d="M214.603 137.511L181.693 149.972L181.801 150.256L214.711 137.796L214.603 137.511Z" fill="black"/>
<path opacity="0.1" d="M219.64 137.463L181.872 152.856L181.988 153.138L219.756 137.745L219.64 137.463Z" fill="black"/>
<path opacity="0.1" d="M225.127 136.146L182.316 155.402L182.442 155.679L225.253 136.423L225.127 136.146Z" fill="black"/>
<path opacity="0.1" d="M229.729 136.148L182.651 156.638L182.773 156.917L229.851 136.427L229.729 136.148Z" fill="black"/>
<path opacity="0.1" d="M239.807 134.444L183.363 158.719L183.484 158.998L239.928 134.724L239.807 134.444Z" fill="black"/>
<path opacity="0.45" d="M98.5508 112.526L152.329 101.269L185.09 107.506L141.023 121.653L98.5508 112.526Z" fill="white"/>
<path d="M248.09 113.235C248.09 113.235 250.867 114.352 249.239 116.074C247.61 117.796 185.09 135.816 185.09 135.816L248.09 113.235Z" fill="#3A3768"/>
<path d="M247.628 83.0051C247.628 83.0051 240.701 98.117 246.201 110.844L190.896 128.033L185.6 129.15L183.156 118.501L184.791 105.063V97.7093L247.628 83.0051Z" fill="#D5D6DB"/>
<path d="M72.9941 77.5652L149.542 63.6703L250.974 79.7588L187.972 102.343L72.9941 77.5652Z" fill="#7E6A97"/>
<path d="M72.9942 77.5622C72.9942 77.5622 65.8228 91.0676 74.0728 103.542L187.587 128.033C187.587 128.033 180.541 117.266 187.959 102.343L72.9942 77.5622Z" fill="#7E6A97"/>
<path opacity="0.1" d="M72.9942 77.5622C72.9942 77.5622 65.8228 91.0676 74.0728 103.542L187.587 128.033C187.587 128.033 180.541 117.266 187.959 102.343L72.9942 77.5622Z" fill="black"/>
<path opacity="0.1" d="M76.3765 82.2445C76.3765 82.2445 72.2851 92.1264 76.3765 98.5216V82.2445Z" fill="black"/>
<path d="M187.599 128.033L250.971 107.801C250.971 107.801 254.516 108.562 252.224 111.148C249.932 113.734 188.821 130.619 188.821 130.619L74.085 103.542L187.599 128.033Z" fill="#7E6A97"/>
<path opacity="0.1" d="M187.599 128.033L250.971 107.801C250.971 107.801 254.516 108.562 252.224 111.148C249.932 113.734 188.821 130.619 188.821 130.619L74.085 103.542L187.599 128.033Z" fill="black"/>
<path opacity="0.1" d="M182.405 105.063C182.405 105.063 176.293 112.365 182.405 123.318V105.063Z" fill="black"/>
<path opacity="0.1" d="M211.994 104.942L184.583 114.048L184.679 114.336L212.091 105.231L211.994 104.942Z" fill="black"/>
<path opacity="0.1" d="M217.486 104.036L184.576 116.497L184.684 116.781L217.594 104.32L217.486 104.036Z" fill="black"/>
<path opacity="0.1" d="M222.521 103.99L184.752 119.383L184.868 119.664L222.637 104.271L222.521 103.99Z" fill="black"/>
<path opacity="0.1" d="M228.012 102.669L185.201 121.925L185.326 122.203L228.137 102.947L228.012 102.669Z" fill="black"/>
<path opacity="0.1" d="M232.615 102.675L185.537 123.164L185.659 123.443L232.738 102.953L232.615 102.675Z" fill="black"/>
<path opacity="0.1" d="M242.692 100.973L186.248 125.247L186.369 125.526L242.813 101.252L242.692 100.973Z" fill="black"/>
<path opacity="0.3" d="M101.432 79.0499L155.21 67.7929L187.972 74.0299L143.904 88.1773L101.432 79.0499Z" fill="white"/>
<path d="M250.971 79.7589C250.971 79.7589 253.752 80.8754 252.12 82.6005C250.488 84.3256 187.972 102.343 187.972 102.343L250.971 79.7589Z" fill="#7E6A97"/>
<path d="M214.158 97.4111C214.453 97.4111 214.692 97.1727 214.692 96.8787C214.692 96.5846 214.453 96.3463 214.158 96.3463C213.862 96.3463 213.623 96.5846 213.623 96.8787C213.623 97.1727 213.862 97.4111 214.158 97.4111Z" fill="#4B4B5B"/>
<path d="M211.05 98.2904C211.346 98.2904 211.585 98.052 211.585 97.758C211.585 97.4639 211.346 97.2255 211.05 97.2255C210.755 97.2255 210.516 97.4639 210.516 97.758C210.516 98.052 210.755 98.2904 211.05 98.2904Z" fill="#4B4B5B"/>
<path opacity="0.1" d="M208.606 105.431C208.606 105.431 214.922 101.342 222.967 105.297" stroke="black" stroke-miterlimit="10"/>
</svg>

</template>

<script>
export default {
name:"StepSixIllustration"
}
</script>

<style>

</style>