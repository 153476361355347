<template>
  <div class="px-5 pt-5 pb-10 flex flex-col justify-between h-full overflow-scroll">
    <div>
      <router-link :to="{ name: 'StepFour' }">
       <BackIcon class="mb-5" />
      </router-link>
    </div>

    <div class="">
      <div class="mb-16 flex justify-center">
        <StepSixIllustration />
      </div>
      <div class="flex flex-col items-center mb-12">
        <span class="text-lg font-light">If you want a new habit to last</span>
        <h3 class="text-lg text-center px-10">
          stack it on an already
          existing habit </h3>
      </div>

      <div class="flex space-x-1 items-center justify-center mb-8">
        <div v-for="item in items" :key="item.id"
          class=" rounded-full h-2 w-2 bg-customPurple"></div>
      </div>
      <router-link class="w-10/12" :to="{ name: 'StepSix' }">
        <MainButton text="Got it" class="h-14" />
      </router-link>
    </div>
  </div>
</template>

<script>
import BackIcon from "@/components/svg/BackIcon.vue";
import MainButton from "@/components/shared/MainButton.vue";
import StepSixIllustration from "@/components/svg/onBoarding/StepSixIllustration.vue";

export default {
  name: "StepFive",

  components: {
    BackIcon,
    MainButton,
    StepSixIllustration,
  },
  data() {
    return {
      items: ["Item1", "Item2", "Item3", "Item4", "Item5", "Item6"],
    };
  },
};
</script>

<style>
</style>